<template>
  <b-card
    class="datacenterintobound-edit-wrapper"
  >
    <!-- form -->
    <b-form id="datacenterintoboundForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库名称"
            label-for="wh_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="wh_name"
              size="sm"
              v-model="datacenterintobound.wh_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="提货日期"
            label-for="expct_pick_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="expct_pick_time"
              size="sm"
              v-model="datacenterintobound.expct_pick_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品名称"
            label-for="product_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_name"
              size="sm"
              v-model="datacenterintobound.product_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="datacenterintobound.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部商品名称"
            label-for="out_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_name"
              size="sm"
              v-model="datacenterintobound.out_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部商品ID"
            label-for="out_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_id"
              size="sm"
              v-model="datacenterintobound.out_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="sku规格描述"
            label-for="sku"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="sku"
              size="sm"
              v-model="datacenterintobound.sku"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="确认入库数量"
            label-for="qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="qty"
              size="sm"
              v-model="datacenterintobound.qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库时间"
            label-for="inbound_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inbound_time"
              size="sm"
              v-model="datacenterintobound.inbound_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库批次号"
            label-for="inbound_batch"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inbound_batch"
              size="sm"
              v-model="datacenterintobound.inbound_batch"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库类型Id"
            label-for="warehouse_type_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_type_id"
              size="sm"
              v-model="datacenterintobound.warehouse_type_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库类型名称"
            label-for="warehouse_type_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_type_name"
              size="sm"
              v-model="datacenterintobound.warehouse_type_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="create_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="create_time"
              size="sm"
              v-model="datacenterintobound.create_time"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import datacenterintoboundStore from './datacenterintoboundStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      datacenterintobound: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('datacenterintobound')) store.registerModule('datacenterintobound', datacenterintoboundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('datacenterintobound')) store.unregisterModule('datacenterintobound')
    })

    const edit = function() {
      store.dispatch('datacenterintobound/edit', {id: this.id}).then(res => {
        this.datacenterintobound = res.data.data
      })
    }

    const view = function() {
      store.dispatch('datacenterintobound/view', {id: this.id}).then(res => {
        this.datacenterintobound = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('datacenterintobound/save', this.datacenterintobound).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>